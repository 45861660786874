import React from 'react'
import './about-box.css'

const AboutBox = () => (
    <div>
        <h1 style={{ textAlign: "center" }} className="name-header-h1">Hello, world!</h1>
        <div className="name-header-line"></div>

        <p> I'm Zach Sussman, soon to be a software engineer working on Tesla's Autopilot project.  I'm from sunny (and sometimes rainy) Boca Raton, Florida. </p>
    </div>
)

export default AboutBox;